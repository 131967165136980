class MaterialesRecepcionDetalles {
  constructor({
    id, fk_materiales_recepcion, fk_material, coste, cantidad,
  } = {}) {
    this.id = id
    this.fk_material = fk_material
    this.fk_materiales_recepcion = fk_materiales_recepcion
    this.coste = coste
    this.cantidad = cantidad
  }
}

export default MaterialesRecepcionDetalles
